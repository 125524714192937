
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useBus } from "../../../../bus";
import { Field } from "vee-validate";
import axios from 'axios';
import { apiEndpoint } from '@/mixin/apiMixin.js';
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";

export default defineComponent({
  name: "product_detail-list'",
  components: {Datatable },

  data() {
    return {
      load: false,
      tableData: [],
      componentKey: 0,
      tableHeader: [] as any,
      productData: [] as any,
      viewType: 0,
      loading: false,
    };
  },
  async created() {
    this.emitter.on("product_data", async (product_data) => {
      this.productData = product_data;
      await this.getProductData(product_data);
      this.viewType= this.productData.product_type.id;
      if(this.viewType==1){
        this.tableHeader= [
        {
          name: 'Sales/ Purchase Type',
          key: 'category_name',
          width: '100px',
          // sortable: true,
        },
        {
          name: 'Product Type',
          key: 'product_type',
          width: '100px',
          // sortable: true,
        },
        {
          name: 'VAT %',
          key: 'vat_percent',
          // sortable: true,
        },
        {
          name: 'Size',
          key: 'size_measurement',

          // sortable: true,
        },
        {
          name: 'Weight',
          key: 'weight_measurement',

          // sortable: true,
        },
        {
          name: 'Unit Price',
          key: 'unit_price',

          // sortable: true,
        },
        {
          name: 'Actions',
          key: 'actions',

          // sortable: true,
        },
      ];
      }
      else if(this.viewType==2)
      {
        this.tableHeader= [
            {
              name: 'Sales/ Purchase Type',
              key: 'category_name',
              width: '100px',
              // sortable: true,
            },
            {
              name: 'Product Type',
              key: 'product_type',
              width: '100px',
              // sortable: true,
            },
            {
              name: 'VAT %',
              key: 'vat_percent',
              // sortable: true,
            },
            {
              name: 'SD %',
              key: 'sd_percent',
              // sortable: true,
            },
            {
              name: 'CD %',
              key: 'cd_percent',
              // sortable: true,
            },
            {
              name: 'RD %',
              key: 'rd_percent',

              // sortable: true,
            },
            {
              name: 'Size',
              key: 'size_measurement',

              // sortable: true,
            },
            {
              name: 'Weight',
              key: 'weight_measurement',

              // sortable: true,
            },
            {
              name: 'Unit Price',
              key: 'unit_price',

              // sortable: true,
            },
            {
              name: 'Actions',
              key: 'actions',

              // sortable: true,
            },
          ];
      }
    });
    
  },
  methods: {
    async getProductData(product_data)
    {
      await ApiService.get('finmanInvoice/productInfo/productDetail?product_id='+product_data.id)
        .then((response) => {
          this.tableData = response.data.data;
          this.componentKey += 1;
          this.load = true;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    editProductInfo(data) {
      this.emitter.emit('product-info-edit-type', data);
    },
    async DeleteProductInfo(id)
    {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('finmanInvoice/productInfo/delete/' + `${id}`)
            .then((response) => {
              DrawerComponent?.hideAll();
              this.load = true;
              this.emitter.emit('product-added', true);
              Swal.fire('Deleted!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    }
  },
});
