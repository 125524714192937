
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useBus } from "../../../../bus";
import { Field } from "vee-validate";
import axios from 'axios';
import { apiEndpoint } from '@/mixin/apiMixin.js';
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";

export default defineComponent({
  name: "kt-drawer-categoryEdit",
  components: { Field },

  data() {
    return {
      load: false,
      formData: {
        info_id: "",
        product_id:'',
        category_id: '',
        unit:'',
        unit_type:'',
        size_unit: '',
        size_measurement:'',
        weight_unit:'',
        weight_measurement:'',
        unit_price: '',
        vat_percent: '',
        // sd_percent:'',
        // cd_percent:'',
        // rd_percent:'',
        vds_amount: '',
        vat_ledger_id:'',
        finman_ledger_id: '',
        inventory_ledger_id: '',
        revenue_ledger_id: '',
        cgs_ledger_id: '',

      },
      unitList: [] as any,
      productData: [] as any,
      categoryList: [] as any,
      finmanLedgerList: [] as any,
      loading: false,
    };
  },
  async created() {
    this.emitter.on("product_data", async (product_data) => {
      this.productData = product_data;
      this.formData.product_id= product_data.id;
      await this.getCategoryData(product_data);
      await this.getUnitData();
    });
    
  },
  methods: {
    async getCategoryData(product_data) {
      await ApiService.get('finmanInvoice/category/list?type='+ product_data.product_type_id)
        .then((response) => {
          this.categoryList = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async getUnitData() {
      await ApiService.get('finmanInvoice/unit/list')
        .then((response) => {
          this.unitList = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async getFinmanLedgers(category_id){
      let group_id;
      let instance= this.categoryList.find(element => element.id == category_id);
      group_id= instance.finman_group_id;

      // await ApiService.get('finmanInvoice/ledger/ledgerList?group_id='+group_id)
      //   .then((response) => {
      //     console.log(response.data.data);
      //     this.finmanLedgerList = response.data.data;
      //     this.load = false;
      //   })
      //   .catch(({ response }) => {
      //     console.log(response);
      //     this.load = false;
      //   });
      // const targetUrl = ApiService.FINMAN_API_URL+'/inventory/getInventoryLedgers?group_id=' + group_id;
      const targetUrl = ApiService.FINMAN_API_URL+'/inventory/getAllLedgers';
      axios
          .get(`${targetUrl}`)
          .then((response) => {
            this.finmanLedgerList= response.data;
          })
          .catch((error) => {
            console.error('There was an error!', error);
          });
    },
    async formSubmit() {
      let formData = new FormData();
      for (var key in this.formData) {
        formData.set(key, this.formData[key]);
      }
      this.loading = true;
      await ApiService.post("finmanInvoice/productInfo/infoSave", formData)
        .then((response) => {
          DrawerComponent?.hideAll();
          this.loading = false;
          if (response.status == 200) {
            this.emitter.emit("category-added", true);
            if (response.data.status == "error") {
              Swal.fire({
                title: "Error!",
                text: response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Close",
                customClass: {
                  confirmButton: "btn btn-danger",
                },
              });
            } else {
              Swal.fire({
                title: "Success!",
                text: response.data.data,
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              }).then(() => {});
            }
          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Please check all the required field",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
          console.log(response);
        });
    },
  },
});
