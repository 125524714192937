
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useBus } from "../../../../bus";
import { Field } from "vee-validate";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";

export default defineComponent({
  name: "kt-drawer-categoryEdit",
  components: { Field },

  data() {
    return {
      load: false,
      productData: [] as any,
      formData: {
        brand:'',
        category_name:'',
        cd_percent:'',
        color:'',
        info_id:'',
        inventory_ledger_id:'',
        // size_measurement:'',
        // weight_measurement:'',
        // size_unit:'',
        // weight_unit:'',
        model:'',
        product_name:'',
        product_type:'',
        product_type_id:'',
        rd_percent:'',
        revenue_ledger_id:'',
        cgs_ledger_id:'',
        sd_percent:'',
        // unit_type:'',
        unit_price:'',
        vat_percent:'',
        vds_amount:'',
      },
      unitList: [] as any,
      loading: false,
    };
  },
  async created() {
    this.emitter.on("product-info-edit-type", async (data) => {
      this.formData = data;
      await this.getUnitData();
    });
  },
  methods: {
    async getUnitData() {
      await ApiService.get('finmanInvoice/unit/list')
        .then((response) => {
          this.unitList = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async formSubmit() {
      if(this.formData.product_type_id=='1')
      {
        this.formData.sd_percent= '0';
        this.formData.cd_percent= '0';
        this.formData.rd_percent= '0';
        this.formData.cgs_ledger_id= '0';
        this.formData.inventory_ledger_id= '0';
      }
      else if(this.formData.product_type_id=='2')
      {
        this.formData.cgs_ledger_id= '0';
        this.formData.revenue_ledger_id= '0';
      }
      let formData = new FormData();
      for (var key in this.formData) {
        formData.set(key, this.formData[key]);
      }
      this.loading = true;
      await ApiService.post("finmanInvoice/productInfo/infoSave", formData)
        .then((response) => {
          DrawerComponent?.hideAll();
          this.loading = false;
          if (response.status == 200) {
            // this.emitter.emit("category-added", true);
            if (response.data.status == "error") {
              Swal.fire({
                title: "Error!",
                text: response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Close",
                customClass: {
                  confirmButton: "btn btn-danger",
                },
              });
            } else {
              Swal.fire({
                title: "Success!",
                text: response.data.data,
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              }).then(() => {});
            }
          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Please check all the required field",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
          console.log(response);
        });
    },
  },
});
