
import ApiService from '@/core/services/ApiService';
import { defineComponent, ref } from 'vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { useBus } from '../../../../bus';
import { Field } from 'vee-validate';
import axios from 'axios';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  name: 'kt-drawer-addProductInfo',
  components: { Field },

  data() {
    return {
      load: false,
      userInfo: '',
      formData: {
        product_id:0,
        product_info_id:0,
        product_category_id: '',
        product_type_id: '',
        product_name: '',
        brand: '',
        model: '',
        color: '',
        country_origin: '',
        unit_type: '',
        size_measurement: '',
        size_unit: '',
        weight_measurement: '',
        weight_unit: '',
        unit_price: '',
        revenue_ledger_id: '',
        vat_ledger_id:'',
        inventory_ledger_id: '',
        ait_ledger_id:'',
        at_ledger_id:'',
      },
      unitList: [] as any,

      categoryList: [] as any,
      countryList: [] as any,
      modelList: [] as any,
      productList: [] as any,
      finmanLedgerList: [] as any,
      measurementList: [
        {
          id: 1,
          measurement_name: 'inche',
        },
      ] as any,
      loading: false,
      cardHeading: 'Add Product' as any
    };
  },
  async created() {
    this.load = true;
    this.cardHeading='Add Product';
    await this.getProductTypeData();
    await this.getProductModelData();
    await this.getCountryList();
    await this.getUnitData();
    this.emitter.on("product-edit-type", async (data) => {
      await this.getCategoryData(data.product_type_id);
      await this.getFinmanLedgers(data.category_id);
      this.formData = 
      {
        product_id: data.product_id,
        product_info_id: data.product_info_id,
        product_category_id: data.category_id,
        product_type_id: data.product_type_id,
        product_name: data.product_name,
        brand: data.brand,
        model: data.model,
        color: data.color,
        country_origin: data.country_origin,
        unit_type: 'both',
        size_measurement: data.size_measurement,
        size_unit: data.size_unit,
        weight_measurement: data.weight_measurement,
        weight_unit: data.weight_unit,
        unit_price: data.unit_price,
        revenue_ledger_id: data.revenue_ledger_id,
        vat_ledger_id: data.vat_ledger_id,
        inventory_ledger_id: data.inventory_ledger_id,
        ait_ledger_id: data.ait_ledger_id,
        at_ledger_id: data.at_ledger_id,
      };
      this.cardHeading='Edit Product';
    });
  },
  methods: {
    async getUnitData() {
      await ApiService.get('finmanInvoice/unit/list')
        .then((response) => {
          this.unitList = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async getCategoryData(type_id) {
      await ApiService.get('finmanInvoice/category/list?type=' + type_id)
        .then((response) => {
          this.categoryList = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async getCountryList() {
      this.load = true;
      await ApiService.get('finmanInvoice/product/countryList')
        .then((response) => {
          this.countryList = response.data.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          this.load = false;
        });
    },
    async getProductModelData() {
      this.load = true;
      await ApiService.get('finmanInvoice/product_model')
        .then((response) => {
          this.modelList = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async getProductTypeData() {
      this.load = true;
      await ApiService.get('finmanInvoice/product_type')
        .then((response) => {
          this.productList = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async getFinmanLedgers(category_id) {
      let group_id;
      let instance = this.categoryList.find(element => element.id == category_id);
      group_id = instance.finman_group_id;
      const targetUrl = ApiService.FINMAN_API_URL + '/inventory/getAllLedgers';
      axios
        .get(`${targetUrl}`)
        .then((response) => {
          this.finmanLedgerList = response.data;
        })
        .catch((error) => {
          console.error('There was an error!', error);
        });
    },
    async formSubmit() {
      let formData = new FormData();
      for (var key in this.formData) {
        formData.set(key, this.formData[key]);
      }

      this.loading = true;
      await ApiService.post('finmanInvoice/product_info/save', formData)
        .then((response) => {
          DrawerComponent?.hideAll();
          this.loading = false;
          if (response.status == 200) {
            this.emitter.emit('product-added', true);
            Swal.fire({
              title: 'Success!',
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              for (let key in this.formData) {
                if (this.formData.hasOwnProperty(key)) {
                  this.formData[key] = '';
                }
              }
            });
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: 'Please check all the required field',
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html: response.data.error,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },
  },
});
